
import React, { useEffect, useState } from 'react';

import styles from "./Subscription.module.scss";
import URLButton from "./urlButton/urlButton";

const TVButton = props => {
  const { setState, actionProvider } = props;

 return (
        <>
           <div className={`${styles.mainContainer} flex-column`}>
              {/* <div className={`flex-column cardsWrapper`}> */}
                   <URLButton text="TV guide"  url = "https://everdance.app/faq#rec636648237" />
              {/* </div> */}
            </div>
        </>
    )
};

export default TVButton;