import { createChatBotMessage } from 'react-chatbot-kit';
import MainQuestions from './MainQuestions.jsx'
import SubscriptionQuestions from './SubscriptionQuestions.jsx'
import AccountQuestions from './AccountQuestions.jsx'
import AppQuestions from './AppQuestions.jsx'
import TVButton from './TVButton.jsx'
import CooperationButton from './CooperationButton.jsx'
import OnboardingButton from './OnboardingButton.jsx'
import StoreButton from './StoreButton.jsx'
import SelectSubscription from './SelectSubscription.jsx'
import UploadFile from './UploadFile.jsx'
import PlanQuestions from './PlanQuestions.jsx'
import PlanChangeOptions from './PlanChangeOptions.jsx'
import AccessClassesQuestions from './AccessClassesQuestions.jsx'
import HaveQuestionsOptions from './HaveQuestionsOptions.jsx'

const botName = 'Everdance bot';

const config = {
  initialMessages: [createChatBotMessage(`Hi! I am an Everdance bot 👋🏽 \nI will help you. Please ask any question below. Or you can select your topic from the list below`,
  {
       widget: 'mainQuestions',
  })
  ],
  botName: botName,
  customStyles: {
      botMessageBox: {
        backgroundColor: '#376B7E',
      },
      chatButton: {
        backgroundColor: '#5ccc9d',
      },
    },
  widgets: [
      {
        widgetName: 'mainQuestions',
        widgetFunc: (props) => <MainQuestions {...props}/>,
        mapStateToProps: ["selectedQuestion"],
      },
      {
        widgetName: 'appQuestions',
        widgetFunc: (props) => <AppQuestions {...props}/>,
        mapStateToProps: ["selectedQuestion"],
      },
      {
        widgetName: 'accountQuestions',
        widgetFunc: (props) => <AccountQuestions {...props}/>,
        mapStateToProps: ["selectedQuestion"],
      },
      {
        widgetName: 'planQuestions',
        widgetFunc: (props) => <PlanQuestions {...props}/>,
        mapStateToProps: ["selectedQuestion"],
      },
      {
        widgetName: 'planChangeOptions',
        widgetFunc: (props) => <PlanChangeOptions {...props}/>,
        mapStateToProps: ["selectedQuestion"],
      },
      {
        widgetName: 'haveQuestions',
        widgetFunc: (props) => <HaveQuestionsOptions {...props}/>,
        mapStateToProps: ["selectedQuestion"],
      },
      {
        widgetName: 'accessClasses',
        widgetFunc: (props) => <AccessClassesQuestions {...props}/>,
        mapStateToProps: ["selectedQuestion"],
      },
      {
        widgetName: 'tvButton',
        widgetFunc: (props) => <TVButton {...props}/>,
      },
      {
         widgetName: 'onboarding',
         widgetFunc: (props) => <OnboardingButton {...props}/>,
      },
      {
         widgetName: 'store',
         widgetFunc: (props) => <StoreButton {...props}/>,
      },

      {
        widgetName: 'cooperateButton',
        widgetFunc: (props) => <CooperationButton {...props}/>,
      },
      {
        widgetName: 'subscriptionQuestions',
        widgetFunc: (props) => <SubscriptionQuestions {...props}/>,
        mapStateToProps: ["subscriptionQuestions"],
      },
      {
        widgetName: 'selectSubscription',
        widgetFunc: (props) => <SelectSubscription {...props}/>,
        mapStateToProps: ["selectSubscription"],
      },
      {
        widgetName: 'uploadFile',
        widgetFunc: (props) => <UploadFile {...props}/>,
        mapStateToProps: ["selectFile"],
      },
    ],

};

export default config;