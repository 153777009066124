
import React, { useEffect, useState } from 'react';

import styles from "./Subscription.module.scss";
import URLButton from "./urlButton/urlButton";

const OnboardingButton = props => {
  const { setState, actionProvider } = props;

 return (
        <>
           <div className={`${styles.mainContainer} flex-column`}>
              {/* <div className={`flex-column cardsWrapper`}> */}
                   <URLButton text="Start your dance journey now"  url = "https://everdance.org/" />
              {/* </div> */}
            </div>
        </>
    )
};

export default OnboardingButton;