
async function sendConfirmEmail(email) {

    // send info to server about this user
    let chatID = null;
    let trials = 0;
    try{
    trials += 1;
    await fetch('https://t2lowguqdl.execute-api.us-east-1.amazonaws.com/dev/send_email_confirm_code', {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            chat_id: localStorage.getItem('chat_bot_id'),
            email: email,
        })

    }).then(async (response) => {
        console.log("result");
        let resultBody = response.json();
        await resultBody.then(async (json) => {
            console.log(json);
             return true;
        });
    });
    return true;
    } catch(e){
     console.log(e);
     if(trials< 4){
     return sendConfirmEmail(email);
      } else{
       console.log("last chance");
       return false;
      }

    }

}

export default sendConfirmEmail

