
import React, { useEffect, useState } from 'react';

import SubscriptionCard from "./checkboxCard/SubscriptionCard";
import styles from "./Subscription.module.scss";

const SelectSubscription = props => {

  const { setState, actionProvider } = props;
  const [imageUrl, setImageUrl] = useState('');
  const [cards, setCards] = useState();
  const [activeCards, setActiveCards] = useState([]);

   const selectCard = card => {
          if(card.checked) {
              setActiveCards((prev => [...prev, card]))
          } else {
              const removeCardIndex = activeCards.findIndex(el => el.id === card.id);
              setActiveCards([...activeCards.slice(0, removeCardIndex), ...activeCards.slice(removeCardIndex + 1)])
          }
          
           actionProvider.handleCancelSelectedSubscription(card);
      }

let subscriptions = [];
let subscriptionsItems = localStorage.getItem('subscriptions');

let parsedData = JSON.parse(subscriptionsItems);

for(let s = 0; s < parsedData.length; s++){

subscriptions.push(parsedData[s]);
}


 return (
        <>
            <div className={`${styles.mainContainer} flex-column`}>
{/*                 <h1 className={styles.cardTitle}>Select your question?</h1> */}
{/*                 <span className={`${styles.marginTitle} componentSubtitle`}>We will try to help you.</span> */}
                <div className={`flex-column cardsContainer`}>
                    <div className={`flex-column cardsWrapper`}>
                        { subscriptions.map(card => ( <SubscriptionCard key={card.id} data={card} selectCard={selectCard}/> )) }
                    </div>
                </div>
            </div>
        </>
    )
};

export default SelectSubscription;