// in ActionProvider.jsx
import React from 'react';
import {useEffect, useState, useRef} from 'react';
import userSession from "./service/user_session.jsx";
import sendConfirmEmail from "./service/confirm_email.jsx";
import checkConfirmEmail from "./service/check_confirm_email.jsx";

const ActionProvider = ({createChatBotMessage, setState, children}) => {
    const [expectedAnswer, setExpectedAnswer] = useState([]);
    const [userName, setUserName] = useState(null);
    const [userNickname, setUserNickname] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [userId, setUserId] = useState(null);
    const [userGreeted, setUserGreeted] = useState(false);
    const [negation, setNegation] = useState(false);
    const [hasOpinion, setHasOpinion] = useState(false);
    const [hasAttitude, setHasAttitude] = useState(false);
    const [hasDoubt, setHasDoubt] = useState(false);
    const [hasQuestion, setHasQuestion] = useState(false);
    const [chatId, setChatId] = useState(null);
    const [subscriptions, setSubscriptions] = useState(null);
    const [lastActiveTime, setLastActiveTime] = useState(null);
    const [sessionActive, setSessionActive] = useState(false);
    const [timer, setTimer] = useState();
    let lastBotMessageElement;

    const categoriesRef = useRef();


    const mutationObserver = new MutationObserver(async () => {
        console.log('mutationObserver operation');
        scrollDown();
    });

    useEffect(() => {

          let inputElement = document.getElementsByClassName('react-chatbot-kit-chat-input');

           // console.log(inputElement);
           console.log(inputElement);
           var inputArr = Array.from(inputElement);

           //console.log(Object.entries(inputElement.item(0)));
           var textAreaElement = document.createElement("textarea");
           textAreaElement.classList.add('react-chatbot-kit-chat-input');
           textAreaElement.setAttribute('placeholder', "Enter your message");

           let inputPropertyObjectValue;
           let inputPropertyObjectKey;

           for (const [key, value] of Object.entries(inputElement.item(0))) {

                if(key.includes("reactProps")){
                  inputPropertyObjectKey = value.onChange;
                  inputPropertyObjectValue = value.value;
                  console.log(value.value);
                  textAreaElement.setAttribute('value', inputPropertyObjectValue);
                  textAreaElement.addEventListener('change', inputPropertyObjectKey);

               }
           }

           inputArr[0].parentNode.replaceChild(textAreaElement, inputArr[0]);
          // Object.defineProperty(textAreaElement, inputPropertyObjectKey, inputPropertyObjectValue);
           console.log(inputPropertyObjectKey)
           console.log(inputPropertyObjectValue)

       console.log(categoriesRef)
       if (categoriesRef !== null && categoriesRef.current) {
         mutationObserver.observe(categoriesRef.current, {
           childList: true,
           subtree: true,
         });

         return () => {
           mutationObserver.disconnect();
         };
       }
    }, []);

     useEffect(() => {
     console.log("ActionProvider useEffect")
            setTimer(setInterval(() => checkSessionInactive(), 1000*60));
            return () => {
                console.log("ActionProvider useEffect return")
                setLastActiveTime(Date.now());
                clearInterval(timer);
            }
     }, [])

     const scrollDown = () => {
           let elementsList = document.getElementsByClassName('react-chatbot-kit-chat-bot-message-container');
           console.log(elementsList);
           var arr = Array.from(elementsList);
           console.log(elementsList.length)
           lastBotMessageElement = arr.at(elementsList.length - 1);
           console.log("last child")
           console.log(lastBotMessageElement)
           console.log(lastBotMessageElement.offsetTop)
           lastBotMessageElement?.scrollIntoView({
               behavior: 'smooth'
           });
     }

     const checkSessionInactive = () => {

     console.log("checkSessionInactive " + localStorage.getItem('lastActiveTime'));
       if(localStorage.getItem('lastActiveTime') !== null && localStorage.getItem('lastActiveTime') !== undefined){
          if((Date.now() - 1000*60) > localStorage.getItem('lastActiveTime')){
               const haveQuestionMessage = createChatBotMessage('Do you still have questions?',
               {
               widget: "haveQuestions"
               }
               );

                            setState((prev) => ({
                                ...prev,
                                messages: [...prev.messages, haveQuestionMessage],
                            }));
          }
          if((Date.now() - 2000*60) > localStorage.getItem('lastActiveTime')){
                      const sessionCloseMessage = createChatBotMessage('Thank you for your time. Your session is automatically closed. To start again, write any question below.');

                                   setState((prev) => ({
                                       ...prev,
                                       messages: [sessionCloseMessage],
                      }));
           clearInterval(timer);
           setLastActiveTime(null);
           localStorage.removeItem('lastActiveTime');
          }
       }
     }

    const handleEmail = async (email, task) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log("handle email: " + email);
        console.log("email task: " + task);
        if (task !== null || (expectedAnswer !== null && expectedAnswer.length > 0)) {
            if (expectedAnswer.includes("subscr_email_cancel") || task === "cancel_subscr") {
                // go to server and check if any subscr exists for this email
                console.log("check email subscr " + email)
                setUserEmail(email)
                const botMessage = createChatBotMessage('Please wait for a moment');

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, botMessage],
                }));

                // ask verification
               let sent =  await sendConfirmEmail(email);
               console.log("email sent: " + sent);

                await userSession(chatId, userId, userName, userNickname, userEmail, "subscr_cancel", email);

                const confirmMessage = createChatBotMessage('For the security reason we sent you a code on your email for confirmation. Please enter in below to continue');

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, confirmMessage],
                }));

                expectedAnswer.push("subscr_cancel_confirm_code");
                setExpectedAnswer(expectedAnswer);

            }
            if (expectedAnswer.includes("login_problem_email")) {
                setUserEmail(email)
                const botMessage = createChatBotMessage('Please wait for a moment');

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, botMessage],
                }));

                // ask verification
                await sendConfirmEmail(email);

                await userSession(chatId, userId, userName, userNickname, userEmail, "subscr_cancel", email);

                const confirmMessage = createChatBotMessage('For the security reason we sent you a code on your email for confirmation. Please enter in below to continue');

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, confirmMessage],
                }));

                expectedAnswer.push("login_problem_email_confirm_code");
                setExpectedAnswer(expectedAnswer);

            }

            if (expectedAnswer.includes("subscr_email_usage") || expectedAnswer.includes("subscr_email_class_access")) {
                // go to server and check if any subscr exists for this email
                console.log("check email subscr " + email)
                setUserEmail(email)
                const botMessage = createChatBotMessage('Please wait for a moment');

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, botMessage],
                }));
                // save session
                await userSession(chatId, userId, userName, userNickname, userEmail, "subscr_usage", email);

                // search email
                let searchResult = await searchUserByEmail(email.toLowerCase());


                console.log(searchResult)
                if (searchResult.subscription !== null && searchResult.subscription !== undefined && searchResult.subscription.length > 0) {
                    let webSubscrInfo = false;
                    let appSubscrInfo = false;
                    for (let s = 0; s < searchResult.subscription.length; s++) {
                        if (searchResult.subscription[s].platform === "stripe" || searchResult.subscription[s].platform === "paypal") {
                            // explain how to use subscription from web
                            if (webSubscrInfo === false) {

                                let webAnswer = 'Ok, we checked everything! We now see that you bought a subscription through our website. ' +
                                    'It means that you have full access to application content during your subscription period ' +
                                    'by using your personal account in the application. ' +
                                    'Use your email to log in to your account: ' +
                                    'select `letter` icon on login pop up and enter your email ' +
                                    'and password created by you during registration process after subscription purchase .' +
                                    'Please note that deletion of your profile and/or the application doesn\'t stop autorenewal of your subscription';
                                const botMessage = createChatBotMessage(webAnswer);

                                setState((prev) => ({
                                    ...prev,
                                    messages: [...prev.messages, botMessage],
                                }));
                                webSubscrInfo = true;

                            }
                        } else {
                            if (appSubscrInfo === false) {

                                // go to stores
                                let storeAnswer = 'Ok, we checked everything! We now see that you bought a subscription through the store. ' +
                                    'It means that you have full access to application content during your subscription period ' +
                                    'on each device that uses the same ' + (searchResult.subscription[s].platform === "ios" ? "App Store" : "Play Market") + " account. " +
                                    'No additional action is required to use your subscription. ' +
                                    'Please note that deletion of your profile and/or the application doesn\'t stop autorenewal of your subscription' +
                                    'To restore your access by subscription after app reinstall or installation to another device, please select Restore button on the ' +
                                    ' subscription paywall in the app.';
                                const botMessage = createChatBotMessage(storeAnswer);

                                setState((prev) => ({
                                    ...prev,
                                    messages: [...prev.messages, botMessage],
                                }));
                                appSubscrInfo = true;

                            }
                        }
                    }
                } else {
                    let subscrAnswer = 'We cannot find any subscription by your email. If you have a profile in the app, please send your @nickname.';
                    const botMessage = createChatBotMessage(subscrAnswer);
                    expectedAnswer.push("subscr_nickname_cancel");
                    setExpectedAnswer(expectedAnswer);
                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, botMessage],
                    }));

                }
            }
            if (expectedAnswer.includes("subscr_email_info")) {
                // go to server and check if any subscr exists for this email
                console.log("check email subscr " + email)
                setUserEmail(email)
                const botMessage = createChatBotMessage('Please wait for a moment');
                await userSession(chatId, userId, userName, userNickname, userEmail, "subscr_info", email);

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, botMessage],
                }));
                // search email
                let searchResult = await searchUserByEmail(email.toLowerCase);
                console.log(searchResult)
                if (searchResult.subscription !== null
                    && searchResult.subscription !== undefined
                    && searchResult.subscription.length > 0) {
                    const botMessage = createChatBotMessage("Here is what we found by your email: ");

                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, botMessage],
                    }));

                    for (let s = 0; s < searchResult.subscription.length; s++) {
                        let datePurchase = searchResult.subscription[s].date_purchased;
                        let duration = searchResult.subscription[s].duration;
                        let price = searchResult.subscription[s].price;
                        let platform = searchResult.subscription[s].platform;
                        let subscrAnswer = "Date purchased : " + datePurchase + "\n"
                            + "Duration: " + duration + "\n"
                            + "Price: " + price + "\n"
                            + "Platform: " + platform;
                        const botMessage = createChatBotMessage(subscrAnswer);

                        setState((prev) => ({
                            ...prev,
                            messages: [...prev.messages, botMessage],
                        }));

                    }
                } else {
                    let subscrAnswer = 'We cannot find any subscription by your email. If you have a profile in the app, please send your @nickname.';
                    const botMessage = createChatBotMessage(subscrAnswer);
                    expectedAnswer.push("subscr_nickname_info");
                    setExpectedAnswer(expectedAnswer);
                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, botMessage],
                    }));

                }
            }
            if (expectedAnswer.includes("subscr_email_refund")) {
                // go to server and check if any subscr exists for this email
                console.log("check email subscr " + email)
                setUserEmail(email)
                const botMessage = createChatBotMessage('Please wait for a moment');
                await userSession(chatId, userId, userName, userNickname, userEmail, "subscr_refund", email);

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, botMessage],
                }));
                // search email
                let searchResult = await searchUserByEmail(email.toLowerCase());
                console.log(searchResult)
                if (searchResult.subscription !== null && searchResult.subscription !== undefined && searchResult.subscription.length > 0) {
                    if (searchResult.subscription.length > 1) {
                        setSubscriptions(searchResult.subscription)
                        localStorage.setItem('subscriptions', JSON.stringify(searchResult.subscription));
                        // user has more than one subscr
                        // ask which to cancel

                        let moreThanOneSubscrAnswer = 'Ok, we checked everything! We now see that you have ' + searchResult.subscription.length +
                            ' subscriptions. Please check it and choose the subscription you would like to stop so we can help you with further actions.';

                        const moreThanOneSubscrMessage = createChatBotMessage(moreThanOneSubscrAnswer, {
                            widget: 'selectSubscription',
                        });

                        setState((prev) => ({
                            ...prev,
                            messages: [...prev.messages, moreThanOneSubscrMessage],
                        }));

                    } else {

                        for (let s = 0; s < searchResult.subscription.length; s++) {
                            if (searchResult.subscription[s].platform === "stripe" || searchResult.subscription[s].platform === "paypal") {
                                // try to cancel
                                let cancelResult = await cancelWebSubscription(searchResult.subscription[s].subscr_id)
                                console.log(cancelResult)
                                if (cancelResult.canceled) {
                                    // offer a free period instead of refund
                                    const botMessage = createChatBotMessage('Don\'t worry, we canceled your subscription, so no more new charges. You\'ll still have access to all classes until the end of your subscription and after - access to 70+ free classes in our app and a feed with inspiring videos. What do you think if we offer you instead of a  refund + 1 free month access to the  app as a gift 🎁 from our team?  \n' +
                                        'Have a nice day!\n');
                                    setExpectedAnswer(["subscr_refund_offer"]);

                                    setState((prev) => ({
                                        ...prev,
                                        messages: [...prev.messages, botMessage],
                                    }));

                                }

                            } else {
                                // go to stores
                                let storeAnswer = 'Ok, we checked everything! We now see that you bought a subscription through the store. ' +
                                    'You can cancel it by yourself through the settings on your phone or contact App Store or Play Market support service. ' +
                                    'You\'ll still have access to all classes until the end of your subscription and after - access to 70+ free classes in our app and a feed with inspiring videos. ' +
                                    'About Refund: only App Store or Play Market support service can deal with it as they receive all payments for the user’s subscriptions. So you need to contact them. ' +
                                    'Have a nice day!';
                                const botMessage = createChatBotMessage(storeAnswer);

                                setState((prev) => ({
                                    ...prev,
                                    messages: [...prev.messages, botMessage],
                                }));

                            }
                        }
                    }

                } else {
                    let subscrAnswer = 'We cannot find any subscription by your email. If you have a profile in the app, please send your @nickname.';
                    const botMessage = createChatBotMessage(subscrAnswer);
                    expectedAnswer.push("subscr_nickname_refund");
                    setExpectedAnswer(expectedAnswer);
                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, botMessage],
                    }));

                }
            }
        } else {
            // ask what user wants
            const botMessage = createChatBotMessage(
                'Could you please clarify your question? Or you can select your topic from the list below',
                {
                    widget: 'mainQuestions',
                }
            );

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
    };

    const handleNegation = (negation) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log("action provider handleNegation");

        /// set negation flag
        setNegation(true);
        if (expectedAnswer.includes('have_subscr')) {
            /// offer
            const botMessage = createChatBotMessage(
                'You\'re lucky! We have a super offer for you! Please follow our questionnaire and then select your subscription with super discount.',
                {
                    widget: 'onboarding',
                }
            );

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
        if (expectedAnswer.includes('subscr_email_cancel')) {
            /// offer
            const botMessage = createChatBotMessage(
                'If you have a profile in our app, please send us your @nickname');

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));


            setExpectedAnswer(["subscr_nickname_cancel"]);
        }
        if (expectedAnswer.includes('subscr_email_refund')) {
            /// offer
            const botMessage = createChatBotMessage(
                'If you have a profile in our app, please send us your @nickname');

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));


            setExpectedAnswer(["subscr_nickname_refund"]);
        }
        if (expectedAnswer.includes('subscr_email_info')) {
            /// offer
            const botMessage = createChatBotMessage(
                'If you have a profile in our app, please send us your @nickname');

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));


            setExpectedAnswer(["subscr_nickname_info"]);
        }
        if (expectedAnswer.includes('subscr_email_usage')) {
            /// offer
            const botMessage = createChatBotMessage(
                'If you have a profile in our app, please send us your @nickname');

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));


            setExpectedAnswer(["subscr_nickname_usage"]);
        }
        if (expectedAnswer.includes('subscr_nickname_cancel')
            || expectedAnswer.includes('subscr_nickname_refund')
            || expectedAnswer.includes('subscr_nickname_info')
            || expectedAnswer.includes('subscr_nickname_usage')) {
            /// offer
            const botMessage = createChatBotMessage(
                'Please send us your payment check. We\'ll try to find your subscription.',
                {
                    widget: "uploadFile"
                }
            );

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));


            setExpectedAnswer([]);
        }
        if (expectedAnswer.includes('user_name')) {
            // ask email
            const botMessage = createChatBotMessage('Ok, no problem! Please write your question or select from the list below.',
                {
                    widget: 'mainQuestions',
                });
            setExpectedAnswer([]);

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
        if (expectedAnswer.includes('subscr_refund_offer')) {
            // send user money policy

            const botMessage = createChatBotMessage('If you\'re looking into a refund, we\'ve set up a special program for our users. Check it out at https://everdance.app/money_back. This ensures that you\'re taken care of if you\'re not happy with the product or for any other legit reasons. Plus, it helps us learn and grow from your valuable feedback!' +
                'Take a peek at the details, and if you run into any hiccups, just give us a shout. We\'re all about individual solutions and will work with you to figure things out. Normally, we tackle refund matters the same day you reach out.' +
                'Wishing you a fantastic day ahead! 😊');

            setExpectedAnswer([]);
            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
    };

    const handleYes = async () => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log(handleYes)
        if (expectedAnswer.includes('have_subscr')) {
            // ask email
            const botMessage = createChatBotMessage('Great! What is your email address provided during purchase?');
            setExpectedAnswer(["subscr_email_class_access"]);
            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }

        if (expectedAnswer.includes('user_name')) {
            // ask email
            const botMessage = createChatBotMessage('Great! What\'s your name?');

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
        if (expectedAnswer.includes('review_offer_question')) {
            // ask email
            const botMessage = createChatBotMessage('Great! So let us know when you\'re ready. And we’ll give this additional month!',
                {
                    widget: "store"
                }
            );

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
        if (expectedAnswer.includes('subscr_refund_offer')) {
            // user agreed to free access
            // grant free access to profile by id
            await userFreeAccess();
            const botMessage = createChatBotMessage('Great! You have one month of limitless access to the app!');
            setExpectedAnswer([]);
            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
    };

    const handleExpressOpinion = (opinion) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log("action provider handleExpressOpinion");
        /// set opinion flag
        setHasOpinion(true);
        if (expectedAnswer.includes("subscr_email_cancel")) {
            if (negation) {
                ///probably user does not know email
                const botMessage = createChatBotMessage('Don\'t worry, if you have a profile in our app, please send us your @nickname');
                setExpectedAnswer(["subscr_nickname_cancel"]);
                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, botMessage],
                }));

            }

        }
    };

    const handleExpressAttitude = (attitude) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log("action provider handleExpressAttitude");
        /// set attitude flag
        setHasAttitude(true);
    };

    const handleExpressDoubt = (doubt) => {

    setLastActiveTime(Date.now());
        console.log("action provider handleExpressDoubt");
        /// set doubt flag
        setHasDoubt(true);
    };

    const handleAsk = (ask) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log("action provider handleAsk");
        /// set ask flag
        setHasQuestion(true);
    };

    const anyText = async (text, hello, name, yes, no) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log("action provider anyText");
        if (expectedAnswer !== null && expectedAnswer.length > 0) {
            if (expectedAnswer.includes("user_name")) {
                /// check name
                setUserName(text);

                await userSession(chatId, userId, userName, userNickname, userEmail, "user_name", text);

                const botMessage = createChatBotMessage('Nice to meet you, ' + text + '! Write your question or select from the list below',
                    {
                        widget: 'mainQuestions',
                    }
                );
                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, botMessage],
                }));

            }
            if (expectedAnswer.includes("nickname_delete_acc")) {
                setUserNickname(text);

                await userSession(chatId, userId, userName, text, userEmail, "nickname_delete_acc", text);

                const deleteAccMessage = createChatBotMessage('To delete your data, please select "Settings" icon on the profile page, choose "Manage my account" option and there you have "Delete my profile" button. Before deleting your profile, you should confirm your password or your method of authentication. You\'ll have 30 days for restoring your profile or you can choose "Delete forever" option, and all your profile data will be deleted immediately');
                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, deleteAccMessage],
                }));

            }

            if (expectedAnswer.includes("subscr_nickname_cancel")) {
                setUserNickname(text);

                await userSession(chatId, userId, userName, text, userEmail, "subscr_nickname_cancel", text);

                const waitMessage = createChatBotMessage('Please wait for a moment');

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, waitMessage],
                }));
                //search for user account by nickname
                let searchResult = await searchUserByNickname(text);
                if (searchResult.subscription !== null && searchResult.subscription !== undefined && searchResult.subscription.length > 0) {
                    if (searchResult.subscription.length > 1) {
                        setSubscriptions(searchResult.subscription)
                        localStorage.setItem('subscriptions', JSON.stringify(searchResult.subscription));
                        // user has more than one subscr
                        // ask which to cancel

                        let moreThanOneSubscrAnswer = 'Ok, we checked everything! We now see that you have ' + searchResult.subscription.length +
                            ' subscriptions. Please check it and choose the subscription you would like to stop so we can help you with further actions.';

                        const moreThanOneSubscrMessage = createChatBotMessage(moreThanOneSubscrAnswer, {
                            widget: 'selectSubscription',
                        });

                        setState((prev) => ({
                            ...prev,
                            messages: [...prev.messages, moreThanOneSubscrMessage],
                        }));

                    } else {
                        // check and cancel

                        for (let s = 0; s < searchResult.subscription.length; s++) {
                            if (searchResult.subscription[s].platform === "stripe" || searchResult.subscription[s].platform === "paypal") {
                                // try to cancel
                                let cancelResult = await cancelWebSubscription(searchResult.subscription[s].subscr_id);

                                console.log(cancelResult)
                                if (cancelResult.canceled) {
                                    const messageCanceled = createChatBotMessage('Don\'t worry, we canceled your subscription, so no more new charges.');

                                    setState((prev) => ({
                                        ...prev,
                                        messages: [...prev.messages, messageCanceled],
                                    }));

                                    const messageAccess = createChatBotMessage('You\'ll still have access to all classes until the end of your subscription and after - access to 70+ free classes in our app and a feed with inspiring videos. ');

                                    setState((prev) => ({
                                        ...prev,
                                        messages: [...prev.messages, messageAccess],
                                    }));

                                    const messageReviewOffer = createChatBotMessage('And if you leave a positive review about us in the Store, then we\'ll give you + 3 months of free subscription as a 🎁 gift from us.',
                                        {
                                            widget: 'store',

                                        }
                                    );

                                    setState((prev) => ({
                                        ...prev,
                                        messages: [...prev.messages, messageReviewOffer],
                                    }));


                                    setExpectedAnswer(["review_offer_question"]);
                                }

                            } else {
                                // go to stores
                                let storeAnswer = 'Ok, we checked everything! We now see that you bought a subscription through the store. ' +
                                    'You can cancel it by yourself through the settings on your phone or contact App Store or Play Market support service. ' +
                                    'You\'ll still have access to all classes until the end of your subscription and after - access to 70+ free classes in our app and a feed with inspiring videos. ' +
                                    'Have a nice day!';
                                const botMessage = createChatBotMessage(storeAnswer);

                                setState((prev) => ({
                                    ...prev,
                                    messages: [...prev.messages, botMessage],
                                }));

                            }
                        }
                    }
                } else {
                    let subscrAnswer = 'We cannot find any subscription by your nickname. Please check your account data and send us information again';
                    const botMessage = createChatBotMessage(subscrAnswer);

                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, botMessage],
                    }));


                }
            }
            if (expectedAnswer.includes("subscr_nickname_info")) {
                setUserNickname(text);

                await userSession(chatId, userId, userName, text, userEmail, "user_nickname_info", text);

                const waitMessage = createChatBotMessage('Please wait for a moment');

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, waitMessage],
                }));
                //search for user account by nickname
                let searchResult = await searchUserByNickname(text);
                if (searchResult.subscription !== null && searchResult.subscription !== undefined && searchResult.subscription.length > 0) {
                    const botMessage = createChatBotMessage("Here is what we found by your email: ");

                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, botMessage],
                    }));

                    for (let s = 0; s < searchResult.subscription.length; s++) {
                        let datePurchase = searchResult.subscription[s].date_purchased;
                        let duration = searchResult.subscription[s].duration;
                        let price = searchResult.subscription[s].price;
                        let platform = searchResult.subscription[s].platform;
                        let subscrAnswer = "Date purchased : " + datePurchase + "\n"
                            + "Duration: " + duration + "\n"
                            + "Price: " + price + "\n"
                            + "Platform: " + platform;
                        const botMessage = createChatBotMessage(subscrAnswer);

                        setState((prev) => ({
                            ...prev,
                            messages: [...prev.messages, botMessage],
                        }));

                    }
                } else {
                    let subscrAnswer = 'We cannot find any subscription by your nickname. Please check your account data and send us information again';
                    const botMessage = createChatBotMessage(subscrAnswer);

                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, botMessage],
                    }));

                }
            }
            if (expectedAnswer.includes("nickname_change_plan_easy")) {
                setUserNickname(text);

                await userSession(chatId, userId, userName, text, userEmail, "nickname_change_plan_easy", text);

                const waitMessage = createChatBotMessage('Please wait for a moment');

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, waitMessage],
                }));
                //search for user account by nickname
                let searchResult = await searchUserByNickname(text);
                // ask email confirmation


                    setUserEmail(searchResult.email);

                    setUserId(searchResult.id);

                     let editResult = await changeDancePlanToEasy(searchResult.id);

                                        console.log(editResult)
                                        if (editResult.status === "changed") {

                                            let describeAnswer = 'All is done! You just need to refresh your plan page to see new plan';
                                            const describeBotMessage = createChatBotMessage(describeAnswer);

                                            setState((prev) => ({
                                                ...prev,
                                                messages: [...prev.messages, describeBotMessage],
                                            }));

                                        } else {
                                            let errorAnswer = 'We are sorry, something gone wrong. We will find out and contact you soon';
                                            const botMessage = createChatBotMessage(errorAnswer);

                                            setState((prev) => ({
                                                ...prev,
                                                messages: [...prev.messages, botMessage],
                                            }));

                                        }

//                     // ask verification
//                     await sendConfirmEmail(searchResult.email);
//
//                     const confirmMessage = createChatBotMessage('For the security reason we sent you a code on your email for confirmation. Please enter in below to continue');
//
//                     setState((prev) => ({
//                         ...prev,
//                         messages: [...prev.messages, confirmMessage],
//                     }));
//
//                     expectedAnswer.push("nickname_change_plan_easy_confirm_code");
//                     setExpectedAnswer(expectedAnswer);


            }
            if (expectedAnswer.includes("nickname_change_plan_hard")) {
                setUserNickname(text);

                await userSession(chatId, userId, userName, text, userEmail, "nickname_change_plan_hard", text);

                const waitMessage = createChatBotMessage('Please wait for a moment');

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, waitMessage],
                }));
                //search for user account by nickname
                let searchResult = await searchUserByNickname(text);
                // ask email confirmation

                    setUserEmail(searchResult.email);
                    setUserId(searchResult.id)
                      let editResult = await changeDancePlanToHard(searchResult.id);

                                        console.log(editResult);
                                        if (editResult.status === "changed") {

                                            let describeAnswer = 'All is done! You just need to refresh your plan page to see new plan';
                                            const describeBotMessage = createChatBotMessage(describeAnswer);

                                            setState((prev) => ({
                                                ...prev,
                                                messages: [...prev.messages, describeBotMessage],
                                            }));

                                        } else {
                                            let errorAnswer = 'We are sorry, something gone wrong. We will find out and contact you soon';
                                            const botMessage = createChatBotMessage(errorAnswer);

                                            setState((prev) => ({
                                                ...prev,
                                                messages: [...prev.messages, botMessage],
                                            }));

                                        }
                    // ask verification
//                     await sendConfirmEmail(searchResult.email);
//
//                     const confirmMessage = createChatBotMessage('For the security reason we sent you a code on your email for confirmation. Please enter in below to continue');
//
//                     setState((prev) => ({
//                         ...prev,
//                         messages: [...prev.messages, confirmMessage],
//                     }));
//
//                     expectedAnswer.push("nickname_change_plan_hard_confirm_code");
//                     setExpectedAnswer(expectedAnswer);

            }
            if (expectedAnswer.includes("subscr_nickname_refund")) {
                setUserNickname(text);
                await userSession(chatId, userId, userName, text, userEmail, "subscr_nickname_refund", text);
                const waitMessage = createChatBotMessage('Please wait for a moment');

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, waitMessage],
                }));
                //search for user account by nickname
                let searchResult = await searchUserByNickname(text);
                if (searchResult.subscription !== null && searchResult.subscription !== undefined && searchResult.subscription.length > 0) {
                    if (searchResult.subscription.length > 1) {
                        setSubscriptions(searchResult.subscription)
                        localStorage.setItem('subscriptions', JSON.stringify(searchResult.subscription));
                        // user has more than one subscr
                        // ask which to cancel

                        let moreThanOneSubscrAnswer = 'Ok, we checked everything! We now see that you have ' + searchResult.subscription.length +
                            ' subscriptions. Please check it and choose the subscription you would like to stop so we can help you with further actions.';

                        const moreThanOneSubscrMessage = createChatBotMessage(moreThanOneSubscrAnswer, {
                            widget: 'selectSubscription',
                        });

                        setState((prev) => ({
                            ...prev,
                            messages: [...prev.messages, moreThanOneSubscrMessage],
                        }));

                    } else {
                        for (let s = 0; s < searchResult.subscription.length; s++) {
                            if (searchResult.subscription[s].platform === "stripe" || searchResult.subscription[s].platform === "paypal") {
                                // try to cancel
                                let cancelResult = await cancelWebSubscription(searchResult.subscription[s].subscr_id)
                                console.log(cancelResult)
                                if (cancelResult.canceled) {
                                    // offer a free period instead of refund
                                    const botMessage = createChatBotMessage('Don\'t worry, we canceled your subscription, so no more new charges. You\'ll still have access to all classes until the end of your subscription and after - access to 70+ free classes in our app and a feed with inspiring videos. What do you think if we offer you instead of a  refund + 1 free month access to the  app as a gift 🎁 from our team?  \n' +
                                        'Have a nice day!\n');
                                    setExpectedAnswer(["subscr_refund_offer"]);

                                    setState((prev) => ({
                                        ...prev,
                                        messages: [...prev.messages, botMessage],
                                    }));

                                }
                            } else {
                                // go to stores
                                let storeAnswer = 'Ok, we checked everything! We now see that you bought a subscription through the store. ' +
                                    'You can cancel it by yourself through the settings on your phone or contact App Store or Play Market support service. ' +
                                    'You\'ll still have access to all classes until the end of your subscription and after - access to 70+ free classes in our app and a feed with inspiring videos. ' +
                                    'About Refund: only App Store or Play Market support service can deal with it as they receive all payments for the user’s subscriptions. So you need to contact them. ' +
                                    'Have a nice day!';
                                const botMessage = createChatBotMessage(storeAnswer);

                                setState((prev) => ({
                                    ...prev,
                                    messages: [...prev.messages, botMessage],
                                }));

                            }
                        }
                    }

                } else {
                    let subscrAnswer = 'We cannot find any subscription by your nickname. Please check your account data and send us information again';
                    const botMessage = createChatBotMessage(subscrAnswer);

                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, botMessage],
                    }));

                }
            }
            if (expectedAnswer.includes("login_problem_describe")) {
                let describeAnswer = 'Ok, thank you. Our team is already working on your request. We\'ll reach you ASAP';
                const botMessage = createChatBotMessage(describeAnswer);

                setExpectedAnswer([]);

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, botMessage],
                }));

            }
            if (expectedAnswer.includes("login_problem_email_confirm_code")) {
                /// check code
                let confirmed = await checkConfirmEmail(userEmail, text);
                console.log(confirmed);
                if (confirmed.confirmed) {
                    // search email
                    let searchResult = await searchUserByEmail(userEmail.toLowerCase());

                    console.log(searchResult)
                    if (searchResult.id !== null && searchResult.id !== undefined) {
                        setUserId(searchResult.id)
                        //
                        let describeAnswer = 'Ok, we got it. Please describe us your problem in details so that we could send this information to our specialists and they will help you ASAP';
                        const describeBotMessage = createChatBotMessage(describeAnswer);

                        setState((prev) => ({
                            ...prev,
                            messages: [...prev.messages, describeBotMessage],
                        }));

                        let uploadAnswer = 'Use upload file option if you have a screenshot or a video record where you can see a problem. This will help us to solve your issue faster.';
                        const uploadBotMessage = createChatBotMessage(uploadAnswer, {
                            widget: 'uploadFile',
                        });


                        setState((prev) => ({
                            ...prev,
                            messages: [...prev.messages, uploadBotMessage],
                        }));


                        setExpectedAnswer(["login_problem_describe"]);
                    } else {
                        let notFoundAnswer = 'We cannot find any profile by your email. Please send us your nickname in the app';
                        const botMessage = createChatBotMessage(notFoundAnswer);
                        expectedAnswer.push("login_problem_nickname");
                        setExpectedAnswer(expectedAnswer);

                        setState((prev) => ({
                            ...prev,
                            messages: [...prev.messages, botMessage],
                        }));

                    }

                } else {

                    let wrongCode = confirmed.error !== null && confirmed.error !== undefined
                        ? confirmed.error
                        : 'We cannot confirm your email because the code you entered is wrong. Please re-check your email again or check your email address';
                    const wrongCodeMessage = createChatBotMessage(wrongCode);

                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, wrongCodeMessage],
                    }));

                }

            }

            if (expectedAnswer.includes("subscr_cancel_confirm_code")) {
                /// check code
                let confirmed = await checkConfirmEmail(userEmail, text);
                console.log(confirmed);
                if (confirmed.confirmed) {
                    // cancel this subscription
                    // search email
                    let searchResult = await searchUserByEmail(userEmail.toLowerCase());

                    console.log(searchResult)
                    if (searchResult.id !== null && searchResult.id !== undefined) {
                        setUserId(searchResult.id)
                    }
                    if (searchResult.subscription !== null && searchResult.subscription !== undefined && searchResult.subscription.length > 0) {
                        if (searchResult.subscription.length > 1) {
                            setSubscriptions(searchResult.subscription)
                            localStorage.setItem('subscriptions', JSON.stringify(searchResult.subscription));
                            // user has more than one subscr
                            // ask which to cancel

                            let moreThanOneSubscrAnswer = 'Ok, we checked everything! We now see that you have ' + searchResult.subscription.length +
                                ' subscriptions. Please check it and choose the subscription you would like to stop so we can help you with further actions.';

                            const moreThanOneSubscrMessage = createChatBotMessage(moreThanOneSubscrAnswer, {
                                widget: 'selectSubscription',
                            });

                            setState((prev) => ({
                                ...prev,
                                messages: [...prev.messages, moreThanOneSubscrMessage],
                            }));

                        } else {

                            for (let s = 0; s < searchResult.subscription.length; s++) {
                                if (searchResult.subscription[s].platform === "stripe" || searchResult.subscription[s].platform === "paypal") {
                                    // try to cancel
                                    let cancelResult = await cancelWebSubscription(searchResult.subscription[s].subscr_id);

                                    console.log(cancelResult)
                                    if (cancelResult.canceled) {
                                        const messageCanceled = createChatBotMessage('Don\'t worry, we canceled your subscription, so no more new charges.');

                                        setState((prev) => ({
                                            ...prev,
                                            messages: [...prev.messages, messageCanceled],
                                        }));

                                        const messageAccess = createChatBotMessage('You\'ll still have access to all classes until the end of your subscription and after - access to 70+ free classes in our app and a feed with inspiring videos. ');

                                        setState((prev) => ({
                                            ...prev,
                                            messages: [...prev.messages, messageAccess],
                                        }));

                                        const messageReviewOffer = createChatBotMessage('And if you leave a positive review about us in the Store, then we\'ll give you + 3 months of free subscription as a 🎁 gift from us.',
                                            {
                                                widget: "store",

                                            }
                                        );

                                        setState((prev) => ({
                                            ...prev,
                                            messages: [...prev.messages, messageReviewOffer],
                                        }));

                                        const uploadReviewOffer = createChatBotMessage('When you\'re ready, just send us a screenshot with your review, and we\'ll soon reach you!',
                                            {
                                                widget: "uploadFile",

                                            }
                                        );

                                        setState((prev) => ({
                                            ...prev,
                                            messages: [...prev.messages, uploadReviewOffer],
                                        }));

                                        setExpectedAnswer(["review_offer_question"]);
                                    }

                                } else {
                                    // go to stores
                                    let storeAnswer = 'Ok, we checked everything! We now see that you bought a subscription through the store. ' +
                                        'You can cancel it by yourself through the settings on your phone or contact App Store or Play Market support service. ' +
                                        'You\'ll still have access to all classes until the end of your subscription and after - access to 70+ free classes in our app and a feed with inspiring videos. ' +
                                        'Have a nice day!';
                                    const botMessage = createChatBotMessage(storeAnswer);

                                    setState((prev) => ({
                                        ...prev,
                                        messages: [...prev.messages, botMessage],
                                    }));

                                }
                            }
                        }
                    } else {
                        let subscrAnswer = 'We cannot find any subscription by your email. If you have a profile in the app, please send your @nickname.';
                        const botMessage = createChatBotMessage(subscrAnswer);
                        expectedAnswer.push("subscr_nickname_cancel");
                        setExpectedAnswer(expectedAnswer);
                        setState((prev) => ({
                            ...prev,
                            messages: [...prev.messages, botMessage],
                        }));


                    }
                } else {

                    let wrongCode = confirmed.error !== null && confirmed.error !== undefined
                        ? confirmed.error
                        : 'We cannot confirm your email because the code you entered is wrong. Please re-check your email again or check your email address';
                    const wrongCodeMessage = createChatBotMessage(wrongCode);

                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, wrongCodeMessage],
                    }));

                }
            }

            if (expectedAnswer.includes("nickname_change_plan_easy_confirm_code")) {
                /// check code
                let confirmed = await checkConfirmEmail(userEmail, text);
                console.log(confirmed);
                if (confirmed.confirmed) {
                    // chande dance plan
                    let editResult = await changeDancePlanToEasy(userEmail.toLowerCase());

                    console.log(editResult)
                    if (editResult.status === "changed") {

                        let describeAnswer = 'All is done! You just need to refresh your plan page to see new plan';
                        const describeBotMessage = createChatBotMessage(describeAnswer);

                        setState((prev) => ({
                            ...prev,
                            messages: [...prev.messages, describeBotMessage],
                        }));

                    } else {
                        let errorAnswer = 'We are sorry, something gone wrong. We will find out and contact you soon';
                        const botMessage = createChatBotMessage(errorAnswer);

                        setState((prev) => ({
                            ...prev,
                            messages: [...prev.messages, botMessage],
                        }));

                    }

                } else {

                    let wrongCode = confirmed.error !== null && confirmed.error !== undefined
                        ? confirmed.error
                        : 'We cannot confirm your email because the code you entered is wrong. Please re-check your email again or check your email address';
                    const wrongCodeMessage = createChatBotMessage(wrongCode);

                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, wrongCodeMessage],
                    }));

                }

            }
            if (expectedAnswer.includes("nickname_change_plan_hard_confirm_code")) {
                /// check code
                let confirmed = await checkConfirmEmail(userEmail, text);
                console.log(confirmed);
                if (confirmed.confirmed) {
                    // chande dance plan
                    let editResult = await changeDancePlanToHard(userEmail.toLowerCase());

                    console.log(editResult);
                    if (editResult.status === "changed") {

                        let describeAnswer = 'All is done! You just need to refresh your plan page to see new plan';
                        const describeBotMessage = createChatBotMessage(describeAnswer);

                        setState((prev) => ({
                            ...prev,
                            messages: [...prev.messages, describeBotMessage],
                        }));


                    } else {
                        let errorAnswer = 'We are sorry, something gone wrong. We will find out and contact you soon';
                        const botMessage = createChatBotMessage(errorAnswer);

                        setState((prev) => ({
                            ...prev,
                            messages: [...prev.messages, botMessage],
                        }));

                    }

                } else {

                    let wrongCode = confirmed.error !== null && confirmed.error !== undefined
                        ? confirmed.error
                        : 'We cannot confirm your email because the code you entered is wrong. Please re-check your email again or check your email address';
                    const wrongCodeMessage = createChatBotMessage(wrongCode);

                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, wrongCodeMessage],
                    }));

                }

            }
        } else {
            let helloMessage;
            if (name !== null && name !== undefined) {
                helloMessage = createChatBotMessage('Hello! Nice to meet you, ' + name + "!");
            } else {
                helloMessage = createChatBotMessage('Hello! Nice to meet you!');
            }
            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, helloMessage],
            }));
            /// clarify user question
            if (text.includes('weather')) {
                let weatherQuotes = [
                    'Whenever people talk to me about the weather, I always feel certain that they mean something else.',
                    'Sunshine is delicious, rain is refreshing, wind braces up, snow is exhilarating; there is no such thing as bad weather, only different kinds of good weather.',
                    'If the sky had no tears, the world would have no rainbows.'

                ];
                // user wants to talk about smth
                let answerNumber = Math.floor(Math.random() * 2);
                let anyAnswer = weatherQuotes[answerNumber];
                const anyMessage = createChatBotMessage(anyAnswer);

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, anyMessage],
                }));

                // ask the topic
            } else {
                // user wants to talk about smth
                let anyAnswer = 'It\'s interesting topic, however this is something that doesn\'t have relation to the app and we could help you with.';
                const anyMessage = createChatBotMessage(anyAnswer);

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, anyMessage],
                }));

                // ask the topic
            }

        }
    };

    const handleError = async (text, hello, name, email) => {


    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log("action provider handleError");
        await userSession(chatId, userId, userName, userNickname, userEmail, text, text);
        if (hello) {
            let helloMessage;
            if (name !== null && name !== undefined) {
                helloMessage = createChatBotMessage('Hello! Nice to meet you, ' + name + "!");
            } else {
                helloMessage = createChatBotMessage('Hello! Nice to meet you!');
            }
            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, helloMessage],
            }));

        }

        const sorryMessage = createChatBotMessage('Thank you for sharing with us your opinion. We’re very sorry that you had to face any discomfort during app usage, but our team always try to check everything promptly and give you an answer ASAP');

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, sorryMessage],
        }));

        const infoMessage = createChatBotMessage('We understand your concerns, but we want to share the information that users who give us bad reviews do so either because they did not like the content of the application, ' +
            ' or they did not use the application and want a refund because they made a hasty decision, but the purpose of using of our application is not a quest to return funds for a just purchased subscription ☺️.' +
            ' We expect that the user is determined to get much more benefits for him/herself if he/she comes to us and starts spending time for the benefit of physical and mental health.' +
            ' We would also like to point out that our application is a streaming platform that brings together dancers from all over the world who offer their teaching techniques, ' +
            ' as well as users who choose what they like and learn or get inspired. Therefore, of course, there is room for the manifestation of “taste” and personal preferences of the user. ' +
            ' Stay in touch! Have a nice day!');

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, infoMessage],
        }));

        expectedAnswer.push("error_reach_email");
        setExpectedAnswer(expectedAnswer);

    };

    const handleBadEmotions = (text, hello, name, email) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log("action provider handleBadEmotions");

    };

    const handleGoodEmotions = async (text, hello, name, email) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log("action provider handleGoodEmotions");
        await userSession(chatId, userId, userName, userNickname, userEmail, text, text);
        if (hello) {
            let helloMessage;
            if (name !== null && name !== undefined) {
                helloMessage = createChatBotMessage('Hello! Nice to meet you, ' + name + "!");
            } else {
                helloMessage = createChatBotMessage('Hello! Nice to meet you!');
            }
            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, helloMessage],
            }));

        }

        const thanksMessage = createChatBotMessage('Thank you for your support and great review!');

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, thanksMessage],
        }));

        const reachEmailMessage = createChatBotMessage('in the meantime, we ask you to indicate the email address at which it would be convenient for you to receive a response from us');

    };

    const handleHello = (name) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log("action provider handleHello");
        if (userGreeted) {
            const botMessage = createChatBotMessage('Hello again! Write your question or select from the list below',
                {
                    widget: 'mainQuestions',
                }
            );
            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        } else {
            setUserGreeted(true);

            const botMessage =
                createChatBotMessage(name !== null && name !== undefined
                    ? 'Hello! Nice to meet you, ' + name + ". What would you like to ask?"
                    : 'Hello! I\'m Ed, your Everdance support agent. Do you mind writing your name please?');
            if (name === null || name === undefined) {
                setExpectedAnswer(["user_name"]);
            }

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }

    };

    const cancelSubscription = () => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log("action provider cancelSubscription");
        const botMessage = createChatBotMessage(
            'Please select a question about subscription',
            {
                widget: 'subscriptionQuestions',
            }
        );

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));

    };

    const handleRefund = () => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        const botMessage = createChatBotMessage(
            'We have some information for you',
            {
                widget: 'mainQuestions',
            }
        );

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));

    };

    const handleSelectSubscriptionQuestion = async (question, email, name, hello, yes, no, message) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log(question);
        console.log("handleSelectSubscriptionQuestion " + email + " " + name);
        await userSession(chatId, userId, name, userNickname, email, "hello", message);
        if (hello) {
            let botMessage;
            if (name !== null && name !== undefined) {
                setUserName(name);
                botMessage = createChatBotMessage('Nice to meet you, ' + name + '!');
            } else {
                botMessage = createChatBotMessage('Nice to meet you!');
            }

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
        if (question === 1) {
            if (email !== null && email !== undefined) {
                handleEmail(email, "cancel_subscr");
            } else {
                const botMessage = createChatBotMessage('Ok! What is your email address provided during purchase?');
                setExpectedAnswer(["subscr_email_cancel"]);
                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, botMessage],
                }));

            }

        }
        if (question === 2) {
            /// buy subscription
            const botMessage = createChatBotMessage(
                'Nice! We are happy you decided to join our community! Please follow our questionnaire and then select your subscription.',
                {
                    widget: 'onboarding',
                }
            );

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
        if (question === 3) {
            /// subscription usage
            const botMessage = createChatBotMessage('Ok! What is your email address provided during purchase?');
            setExpectedAnswer(["subscr_email_usage"]);
            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
        if (question === 4) {
            /// subscription refund
            const botMessage = createChatBotMessage('Ok! What is your email address provided during purchase?');
            setExpectedAnswer(["subscr_email_refund"]);
            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
        if (question === 0) {
            const botMessage = createChatBotMessage('Ok! What is your email address provided during purchase?');
            setExpectedAnswer(["subscr_email_info"]);
            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
    };

    const handleSelectAppQuestion = async (question) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log("handleSelectAppQuestion " + question)

        if (question === 5) {
            await userSession(chatId, userId, userName, userNickname, userEmail, "account questions", null);
            const botMessage = createChatBotMessage(
                'Please select a question about your Everdance account',
                {
                    widget: 'accountQuestions',
                }
            );

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
        if (question === 2) {

            await userSession(chatId, userId, userName, userNickname, userEmail, "Access to classes", null);

            // Access to classes
            // free classes and paid classes
            let accessResponse = "Ok, what's your problem with classes?";
            const botMessage = createChatBotMessage(accessResponse, {
                               widget: 'accessClasses',
            });

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],

            }));

        }
        if (question === 3) {
            await userSession(chatId, userId, userName, userNickname, userEmail, "My plan", null);
            const planBotMessage = createChatBotMessage(
                'Please select a question about your dance plan',
                {
                    widget: 'planQuestions',
                }
            );

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, planBotMessage],
            }));



        }
//         if (question === 4) {
//             await userSession(chatId, userId, userName, userNickname, userEmail, "Access classes", null);
//             let accessResponse = "To access our closed classes (marked with lock icon on class banner) " +
//                 "you can byu a subscription for different period and view every class in the app " +
//                 "without time or amount limitation."
//             const botMessage = createChatBotMessage(accessResponse);
//
//             setState((prev) => ({
//                 ...prev,
//                 messages: [...prev.messages, botMessage],
//
//             }));
//
//             // subscription usage
//             const botMessageHasSubscr = createChatBotMessage('By the way, do you already have a subscription in our app?');
//             setExpectedAnswer(["have_subscr"]);
//             setState((prev) => ({
//                 ...prev,
//                 messages: [...prev.messages, botMessageHasSubscr],
//             }));
//         }
        if (question === 0) {
            // login problem
            // ask login email
            if (userEmail === null || userEmail === undefined) {
                const botMessage = createChatBotMessage('Ok, got it. What is your email address used for registration?');
                setExpectedAnswer(["login_problem_email"]);
                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, botMessage],
                }));

            }

        }
        if (question === 1) {
            await userSession(chatId, userId, userName, userNickname, userEmail, "TV", null);

            const botMessage = createChatBotMessage(
                'Please follow TV guide',
                {
                    widget: 'tvButton',
                }
            );

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }

    };


    const handleSelectPlanQuestion = async (question) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log(question)
        if (question === 1) {
            await userSession(chatId, userId, userName, userNickname, userEmail, "How to follow Dance Plan?", null);
            let planProgress = "Please note that dance plan progress is linked to your profile that you currently logged in,  " +
                "so if you switch between profiles you will see different plan progress for each one. " +
                "If you don't have a profile, then your progress is saved locally and you may lost your progress in case of app reinstall.  " +
                "If you create a profile, your current plan progress will automatically linked to your profile. ";

            const botMessageProgress = createChatBotMessage(planProgress);

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessageProgress],
            }));

        }
        if (question === 0) {
            await userSession(chatId, userId, userName, userNickname, userEmail, "What is dance plan", null);
            let planResponse = "Dance Plan is the every day lessons challenge. " +
                "We believe that following our plan every day you can develop a habit " +
                "for everyday activity. This is crucial for achievement of your goals. " +
                "Our plan was created by professional dances and specialists that " +
                "have rich experience in this field. ";
            const botMessage = createChatBotMessage(planResponse);

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],

            }));

        }
        if (question === 4) {
            await userSession(chatId, userId, userName, userNickname, userEmail, "How to access Dance Plan?", null);
            let planUsage = "Dance plan is available by subscription or in case you have granted free access.  " +
                "For the day to be considered finished, complete viewing the educational part of the lesson " +
                "by waiting for the screen notifying you that the lesson completed to appear. " +
                "Now you can move to the next class! Your progress is saved and synchronized between your different devices. " +
                "For example, you also can watch lessons on your PC or TV. ";
            const botMessageUsage = createChatBotMessage(planUsage);

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessageUsage],

            }));


        }
        if (question === 2) {
            await userSession(chatId, userId, userName, userNickname, userEmail, "Customize my plan", null);
            const customPlanMessage = createChatBotMessage("Ok, we got it. Please note that after changing the plan your previous plan progress will be lost. To continue, please select preferred option below:",
                {
                    widget: 'planChangeOptions',
                }
            );

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, customPlanMessage],

            }));

        }

        if (question === 3) {
            await userSession(chatId, userId, userName, userNickname, userEmail, "Plan progress issue", null);
            //
            let describeAnswer = 'Ok, we got it. Please describe us your problem in details so that we could send this information to our specialists and they will help you ASAP';
            const describeBotMessage = createChatBotMessage(describeAnswer);

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, describeBotMessage],
            }));


            let uploadAnswer = 'Use upload file option if you have a screenshot or a video record where you can see a problem. This will help us to solve your issue faster.';
            const uploadBotMessage = createChatBotMessage(uploadAnswer, {
                widget: 'uploadFile',
            });


            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, uploadBotMessage],
            }));


            setExpectedAnswer(["plan_problem_describe"]);
        }
    };

    const handleSelectAccountQuestion = async (question) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log(question)
        if (question === 1) {
            const botMessage = createChatBotMessage('What is your nickname in the app?');
            expectedAnswer.push("nickname_delete_acc");
            setExpectedAnswer(expectedAnswer);
            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
        if (question === 0) {
            await userSession(chatId, userId, userName, userNickname, userEmail, "Edit profile", null);
            const botMessage = createChatBotMessage('Ok, we got it. Please select edit icon in the profile and choose data you want to change. After changing your data, select Save button');

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
        if (question === 3 || question === 4) {
            await userSession(chatId, userId, userName, userNickname, userEmail, "Partnership", null);
            const linkMessage = createChatBotMessage('Ok, we got you! Please enter your email address and /or link to your social pages, like Instagram, TikTok etc. ');

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, linkMessage],
            }));
            const reviewMessage = createChatBotMessage('Our creative team will soon review your skills and creativity and make you a proposal for receiving a special role in our community.');

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, reviewMessage],
            }));
            expectedAnswer.push("switch_role_acc_link");

            setExpectedAnswer(expectedAnswer);
        }
        if (question === 2) {
            await userSession(chatId, userId, userName, userNickname, userEmail, "Referral", null);
            const referralInfoMessage = createChatBotMessage('Our app has referral program for bloggers and instructors that allows you to earn from your creativity and social activity. Create new lessons, make inspiring content, invite your friends to be part of our dancing community and stay active!');

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, referralInfoMessage],
            }));
            const switchRoleMessage = createChatBotMessage('To start using our referral program at first please apply for switching your account role to Blogger or Instructor.');

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, switchRoleMessage],
            }));

        }
    };

    const handleChangePlanOption = async (question) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log(question)
        if (question === 0) {
            await userSession(chatId, userId, userName, userNickname, userEmail, "Change plan easier", null);
            const botMessage = createChatBotMessage('What is your nickname in the app?');
            expectedAnswer.push("nickname_change_plan_easy");
            setExpectedAnswer(expectedAnswer);
            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
        if (question === 1) {
            const botMessage = createChatBotMessage('What is your nickname in the app?');
            await userSession(chatId, userId, userName, userNickname, userEmail, "Change plan harder", null);
            expectedAnswer.push("nickname_change_plan_hard");
            setExpectedAnswer(expectedAnswer);
            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
    };

    const handleHaveQuestionsOption = async (question) => {

            console.log(question)
            if (question === 0) {
                await userSession(chatId, userId, userName, userNickname, userEmail, "have_questions", null);
                const botMessage = createChatBotMessage('Ok, please select your question:',{
                widget: 'mainQuestions',
                }
                );

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, botMessage],
                }));
            }
            if (question === 1) {
                const botMessage = createChatBotMessage('Ok, thank you for your time! Stay in touch and have a good day!');
                await userSession(chatId, userId, userName, userNickname, userEmail, "no_questions", null);

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, botMessage],
                }));
            }
    };

    const handleAccessClassQuestion = async (question) => {
    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
            console.log(question)
            if (question === 0) {
                await userSession(chatId, userId, userName, userNickname, userEmail, "Access class - Class won't start", null);
                const sorryMessage = createChatBotMessage('Ok, got it. Thank you for informing us about this issue. We’re very sorry that you had to face this discomfort due to the current situation, but our team will check everything promptly and give you an answer ASAP. ');

                const infoMessage = createChatBotMessage('In most cases, this issue can be related to problem of subscription activation on your device. Please make sure that you\'re logged into your personal profile with full access (in case of purchase on our website) or that you\'re using the same App Store or Google Play account on your device related to your in-app purchase' );

                const askDetailsMessage = createChatBotMessage('If this is not your case, we would like to kindly ask you to provide us some details about this problem. Please send us a screenshot or a video record of the moment where you cas see that something wrong happens',
                  {
                   widget: 'uploadFile',
                });

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, sorryMessage],
                }));

                setState((prev) => ({
                   ...prev,
                   messages: [...prev.messages, infoMessage],
                }));

                setState((prev) => ({
                   ...prev,
                   messages: [...prev.messages, askDetailsMessage],
                }));


            }
            if (question === 1) {
                await userSession(chatId, userId, userName, userNickname, userEmail, "Access class - video freezes", null);
                const botMessage = createChatBotMessage('We got it. Don\'t worry, most such cases happen because of unstable internet connection. ');

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, botMessage],
                }));

                const askClassTitleMessage = createChatBotMessage('If you\'re sure that you don\'t have problem with internet, but the issue continues occurring, please send us the title of the class or send us a screenshot of this class',
                 {
                 widget: 'uploadFile',
                 });

                setState((prev) => ({
                   ...prev,
                  messages: [...prev.messages, askClassTitleMessage],
                }));
                expectedAnswer.push("video_freezes_class_title");
                setExpectedAnswer(expectedAnswer);

            }
            if (question === 2) {
                 await userSession(chatId, userId, userName, userNickname, userEmail, "Access class - app crash", null);
                 const botMessage = createChatBotMessage('We got it. Thank you for informing us about this issue. We’re very sorry that you had to face this discomfort due to the current situation, but our team will check everything promptly and give you an answer ASAP.');

                 setState((prev) => ({
                     ...prev,
                     messages: [...prev.messages, botMessage],
                 }));
                 const askEmailMessage = createChatBotMessage('What email could we use for communication with you after we have updates about your case?');
                 setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, askEmailMessage],
                 }));
                 expectedAnswer.push("app_crash_email");
                 setExpectedAnswer(expectedAnswer);

            }
             if (question === 3) {
                await userSession(chatId, userId, userName, userNickname, userEmail, "Access class - Camera issue", null);
                const permissionMessage = createChatBotMessage('Ok, we caught it. The common reason for your problem is missing camera permission in the app. Please check your camera permission in the settings of your device and make sure that you granted it to Everdance app. ');

                 const askDetailsMessage = createChatBotMessage('If your camera permission settings already include Everdance app, we would like to kindly ask you to provide us some details about this problem. Please send us a screenshot or a video record of the moment where you cas see that something wrong happens',
                   {
                   widget: 'uploadFile',
                 });

                 setState((prev) => ({
                     ...prev,
                     messages: [...prev.messages, permissionMessage],
                 }));

                 setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, askDetailsMessage],
                 }));


            }
           if (question === 4) {

               await userSession(chatId, userId, userName, userNickname, userEmail, "Access class - other", null);

                const askDetailsMessage = createChatBotMessage('Please describe your problem in more details. We would like to kindly ask you to send us a screenshot or a video record of the moment where you cas see that something wrong happens',
                                  {
                                  widget: 'uploadFile',
                                });

                                setState((prev) => ({
                                   ...prev,
                                   messages: [...prev.messages, askDetailsMessage],
                                }));

               expectedAnswer.push("access_lesson_other_details");
               setExpectedAnswer(expectedAnswer);

           }
        };

    const handleCancelSelectedSubscription = async (selectedSubscription) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log("handleCancelSubscription")
        console.log(selectedSubscription)
        if (selectedSubscription.platform === "ios" || selectedSubscription.platform === "android") {
            let storeAnswer = 'Ok, we checked everything! We now see that you bought a subscription through the store. ' +
                'You can cancel it by yourself through the settings on your phone or contact App Store or Play Market support service. ' +
                'You\'ll still have access to all classes until the end of your subscription and after - access to 70+ free classes in our app and a feed with inspiring videos. ' +
                'Have a nice day!';
            const botMessage = createChatBotMessage(storeAnswer);

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }

        if (selectedSubscription.platform === "stripe" || selectedSubscription.platform === "paypal") {
            let cancelResult = await cancelWebSubscription(selectedSubscription.subscr_id);

            console.log(cancelResult)
            if (cancelResult.canceled) {
                const messageCanceled = createChatBotMessage('Don\'t worry, we canceled your subscription, so no more new charges.');

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, messageCanceled],
                }));

                const messageAccess = createChatBotMessage('You\'ll still have access to all classes until the end of your subscription and after - access to 70+ free classes in our app and a feed with inspiring videos. ');

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, messageAccess],
                }));

                const messageReviewOffer = createChatBotMessage('And if you leave a positive review about us in the Store, then we\'ll give you + 3 months of free subscription as a 🎁 gift from us.',
                    {
                        widget: "store",

                    }
                );

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, messageReviewOffer],
                }));

                const uploadReviewOffer = createChatBotMessage('When you\'re ready, just send us a screenshot with your review, and we\'ll soon reach you!',
                    {
                        widget: "uploadFile",
                    }
                );

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, uploadReviewOffer],
                }));

                setExpectedAnswer(["review_offer_question"]);

            }
        }
    };

    const handleUploadFile = (result, error) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        if (result === "ok") {
            const uploadedMessage = createChatBotMessage('All done! We got your files and will back soon');

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, uploadedMessage],
            }));
        } else {
            if (error !== null) {
                const uploadedMessage = createChatBotMessage('Oops! Something went wrong. Please try again');

                setState((prev) => ({
                    ...prev,
                    messages: [...prev.messages, uploadedMessage],
                }));
            }
        }

    }

    const handleSelectMainQuestion = async (question) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        console.log(question)
        if (question === 0) {
            await userSession(chatId, userId, userName, userNickname, userEmail, "App Questions", null);
            const botMessage = createChatBotMessage(
                'Please select your question about application usage',
                {
                    widget: 'appQuestions',
                }
            );

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
        if (question === 1) {
            await userSession(chatId, userId, userName, userNickname, userEmail, "Subscription Questions", null);
            const botMessage = createChatBotMessage(
                'Please select your question about subscription',
                {
                    widget: 'subscriptionQuestions',
                }
            );

            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }
        if (question === 2) {
            await userSession(chatId, userId, userName, userNickname, userEmail, "Cooperation Questions", null);
            const botMessage = createChatBotMessage('Please follow our guide',
                {
                    widget: 'cooperateButton',
                }
            );
            setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));

        }

    };

    const changeDancePlanToEasy = async (userId) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        let changeResult;

        await fetch("https://18eoydi2ag.execute-api.us-east-1.amazonaws.com/dev/change_user_plan", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                body: {
                    id: userId,
                    type: "easy"
                }
            }),
        }).then(async (response) => {
            console.log("plan change result");
            let resultBody = response.json();
            await resultBody.then(async (json) => {
                console.log(json);
                changeResult = json;
                return json
            });
        });
        console.log(changeResult);
        return changeResult
    };

    const changeDancePlanToHard = async (userId) => {

    setLastActiveTime(Date.now());
    localStorage.setItem('lastActiveTime', Date.now());
        let changeResult;

        await fetch("https://18eoydi2ag.execute-api.us-east-1.amazonaws.com/dev/change_user_plan", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                body: {
                    id: userId,
                    type: "hard"
                }
            }),
        }).then(async (response) => {
            console.log("plan change result");
            let resultBody = response.json();
            await resultBody.then(async (json) => {
                console.log(json);
                changeResult = json;
                return json
            });
        });
        console.log(changeResult);
        return changeResult
    };

    const userFreeAccess = async () => {

        let accessResult;
        const nowDate = new Date();
        nowDate.setMonth(nowDate.getMonth() + 1);
        await fetch("https://t2lowguqdl.execute-api.us-east-1.amazonaws.com/dev/set_user_free_access", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                body: {
                    id: userId,
                    toDate: nowDate.getMilliseconds()
                }
            }),
        }).then(async (response) => {
            console.log("free access result");
            let resultBody = response.json();
            await resultBody.then(async (json) => {
                console.log(json)
                accessResult = json
                return json
            });
        });
        console.log(accessResult)
        return accessResult
    };

    const cancelWebSubscription = async (id) => {
        let cancelResult;
        await fetch("https://b7lwa365dl.execute-api.us-east-1.amazonaws.com/dev/cancel_web_subscription", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                body: {
                    subscriptionId: id,
                }
            }),
        }).then(async (response) => {
            console.log("cancel subscription result");
            let resultBody = response.json();
            await resultBody.then(async (json) => {
                console.log(json)
                cancelResult = json
                return json
            });
        });
        console.log(cancelResult)
        return cancelResult
    };

    const searchUserByNickname = async (nickname) => {
        // search nickname
        let searchResult;
        await fetch("https://b7lwa365dl.execute-api.us-east-1.amazonaws.com/dev/seach_user_by_nickname", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                body: {
                    nickname: nickname.toLowerCase(),
                }
            }),
        }).then(async (response) => {

            let resultBody = response.json();
            await resultBody.then(async (json) => {
                console.log(json.result)
                searchResult = json.result
                return json.result
            });
        });
        console.log(searchResult)
        return searchResult
    };

    const searchUserByEmail = async (email) => {
        let searchResult;
        await fetch("https://b7lwa365dl.execute-api.us-east-1.amazonaws.com/dev/seach_user_by_email", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                body: {
                    email: email,
                }
            }),
        }).then(async (response) => {

            let resultBody = response.json();
            await resultBody.then(async (json) => {
                console.log(json.result)
                searchResult = json.result
                return json.result
            });
        });
        console.log(searchResult)
        return searchResult
    };

// Put the handleHello function in the actions object to pass to the MessageParser
    return (
        <div ref={categoriesRef}>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        handleHello,
                        cancelSubscription,
                        handleRefund,
                        handleSelectMainQuestion,
                        handleSelectSubscriptionQuestion,
                        handleSelectAccountQuestion,
                        handleSelectAppQuestion,
                        handleEmail,
                        handleNegation,
                        anyText,
                        handleYes,
                        handleCancelSelectedSubscription,
                        handleError,
                        handleGoodEmotions,
                        handleBadEmotions,
                        handleUploadFile,
                        handleSelectPlanQuestion,
                        handleChangePlanOption,
                        handleAccessClassQuestion,
                        handleHaveQuestionsOption
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;