
import React, { useEffect, useState } from 'react';

import styles from "./Subscription.module.scss";
import URLButton from "./urlButton/urlButton";

const CooperationButton = props => {
  const { setState, actionProvider } = props;

 return (
        <>
           <div className={`${styles.mainContainer} flex-column`}>
              {/* <div className={`flex-column cardsWrapper`}> */}
                   <URLButton text="How to cooperate with us"  url = "https://everdance.app/support" />
              {/* </div> */}
            </div>
        </>
    )
};

export default CooperationButton;