import styles from "./urlButton.module.scss";

const URLButton = props => {
    const { text, url} = props;

    const action = () => {
           // go to url
           window.open(url, '_blank')
        }

    return (
        <button className={`${styles.cardContainer}`} onClick={action}>
            {text}
        </button>
    )
}   

export default URLButton;