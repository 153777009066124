
async function checkConfirmEmail(email, code) {

    // send info to server about this user
    let confirmed;
    let someResult = await fetch('https://t2lowguqdl.execute-api.us-east-1.amazonaws.com/dev/check_email_confirm_code', {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            chat_id: localStorage.getItem('chat_bot_id'),
            code_entered: code,
            email: email,
        })
    }).then(async (response) => {
        console.log("result");
        let resultBody = response.json();
        return await resultBody.then(async (json) => {
            await json;
            console.log(json);
            let confirmedResult = json;
            return confirmedResult;
        });
    });
    console.log("conf", someResult)
    return someResult;

}

export default checkConfirmEmail

