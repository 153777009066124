import { useEffect, useState } from 'react';

import { Routes, Route, useLocation } from 'react-router-dom';

import amplitude from 'amplitude-js';

import ReactPixel from 'react-facebook-pixel';

import queryString from 'query-string';

import ChatBot from './pages/chat_bot/ChatBot';

import React from 'react';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

import basenames from "./core/static/basenames.json";


function App() {
    const location = useLocation();
    const queryParams = queryString.parse(localStorage.getItem('search'));
    const [content, setContent] = useState(basenames);

    const userProperties = {
        utmSource: queryParams.utm_source,
        utmMedium: queryParams.utm_medium,
        utmCampaign: queryParams.utm_campaign,
        utmContent: queryParams.utm_content
    };

    const amplitudeOnInit = (instance) => {
        // instance.logEvent('origin', {value: localStorage.getItem('origin')});
        // instance.logEvent('search', {value: localStorage.getItem('search')});
    };

    amplitude.getInstance().onInit(amplitudeOnInit);
    const initializeAmplitude = amplitude.getInstance().init('0f240f05cd42b2b601303fa61d325c37');
    if(localStorage.getItem('user_id') !== null && localStorage.getItem('user_id') !== undefined ){
        amplitude.getInstance().setUserId(localStorage.getItem('user_id'));
    } else{
        const v4Id = uuidv4();
        localStorage.setItem('user_id', v4Id);
        amplitude.getInstance().setUserId(v4Id);
    }
    amplitude.getInstance().setUserProperties(userProperties);

    useEffect(() => {
        console.log("о");
        const id = Date.now();
        document.cookie = `id=${id}`;
        ReactPixel.init('239948538703054', {external_id: id});

        //amplitude.getInstance().onInit(amplitudeOnInit);
        //amplitude.getInstance().setUserProperties(userProperties);


    }, []);

    let l = window.location;
    let array = l.pathname.split('/');
    let urlParts = l.host.split(".")
    let subdomain = urlParts.length >= 3 ? l.host.split(".")[0] : ''

    console.log("subdomain: " + subdomain)
    localStorage.setItem('subdomain', subdomain)
    localStorage.setItem('liveMode', subdomain !== 'test');
    let pathValue;
    if(l.hash !== null && l.hash !== undefined && l.hash !== ""){
        let index = array.indexOf(basename)
        if (index !== -1) {
            array.splice(index, 1);
        }
        pathValue = array.slice(1, array.length + 1)[0];
    }
    amplitude.getInstance().setUserProperties({"subdomain": subdomain});


    return (
        <div className="App">
            <Routes>
              <Route path='/' element={<ChatBot />} />
            </Routes>
          </div>
        );
}

export default App;
