import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AWS from 'aws-sdk';

import styles from "./Subscription.module.scss";
import Progress_bar from "./progress_bar";

const UploadFile = props => {
   const { setState, actionProvider } = props;
   const [tick, setTick] = useState(0);
   const [timer, setTimer] = useState();
   const [uploadStarted, setUploadStarted] = useState(false);

   const S3_BUCKET = "support-issues-files";
   const REGION = "us-east-1";

   AWS.config.update({
         accessKeyId: "AKIA4P3RMVJYBSU6J5US",
         secretAccessKey: "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",
   });
   const s3 = new AWS.S3({
         params: { Bucket: S3_BUCKET },
         region: REGION,
   });

   let selectedFilesValue = null;

   const [selectedFiles, setSelectedFiles] = useState(null);
   const [uploadedValue, setUploadedValue] = useState(0.0);

   useEffect(() => {
           //setTimer(setInterval(() => setTick(prev => prev + 1), 60));

           return () => {
               setTick(0);
               clearInterval(timer);
           }
       }, [])

   useEffect(() => {
    console.log("use effect");
    console.log(selectedFiles);
       // action on update of selectedFiles
       selectedFilesValue = selectedFiles
   }, [selectedFiles, selectedFilesValue]);

   function handleFileUpload (event){
     console.log("handleFileUpload")
     console.log(event.target.files);
     setSelectedFiles(event.target.files)
     selectedFilesValue = event.target.files;
     console.log(selectedFiles);
     localStorage.setItem('files', selectedFiles);
   };

   const startUpload = async (files) => {
   let uploaded = 0;
     for(let f = 0; f < files.length; f++){

           const params = {
             Bucket: S3_BUCKET,
             Key: localStorage.getItem('chat_bot_id') + "_" + files[f].name,
             Body: files[f],
           };

           var upload = s3
             .putObject(params)
             .on("httpUploadProgress", (evt) => {
              setTimer(setInterval(() => setTick(evt.loaded), 60));
               setUploadedValue(parseInt((evt.loaded * 100) / evt.total))
               console.log(
                 "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
               );
             })
             .promise();

           await upload.then((err, data) => {
             console.log(err);
             console.log(data);
              uploaded++;
             if(err){

             } else{

             }

             //alert("File uploaded successfully.");
           });
        }
        if(uploaded === selectedFiles.length){
          actionProvider.handleUploadFile("ok", null);
          setUploadStarted(false);
        } else{
          actionProvider.handleUploadFile("err", null);
          setUploadStarted(false);
        }
   }

   function handleUpload (){
     setUploadStarted(true);
     // send to server
     console.log(selectedFiles);
     console.log(localStorage.getItem('files'))

     console.log(selectedFiles);
     startUpload(selectedFiles);

   };

   return(
   <>
     <div className={`${styles.mainContainer}`} >
        <input name="file" type="file" id="input__file" className={`${styles.inputFile}`} multiple onChange={handleFileUpload} />
        <label for="input__file" className={`${styles.inputFileButton}`}>
           <span className={`${styles.inputFileIconWrapper}`}><img className={`${styles.inputFileIcon}`} src="/images/icons/my_plan.png" alt="Выбрать файл" width="25"/></span>
           <span className={`${styles.inputFileButtonText}`}>Select files</span>
        </label>
        <button className={styles.upload} onClick={handleUpload}>Upload</button>
       { uploadStarted && (<progress className={styles.progressBar} barClassName={styles.progressBar} value={tick} />)}

     </div>
     </>
   );
};

export default UploadFile;