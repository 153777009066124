
import React, { useEffect, useState } from 'react';

import styles from "./Subscription.module.scss";
import URLButton from "./urlButton/urlButton";

const StoreButton = props => {
  const { setState, actionProvider } = props;

 return (
        <>
           <div className={`${styles.mainContainer} flex-column`}>
              {/* <div className={`flex-column cardsWrapper`}> */}
                   <URLButton text="App Store"  url = "https://apps.apple.com/ru/app/everdance-dance-tutorials/id1492681216?l=en" />
                   <span>or</span>
                   <URLButton text="Play Market"  url = "https://play.google.com/store/apps/details?id=com.everdance.everdance" />
              {/* </div> */}
            </div>
        </>
    )
};

export default StoreButton;