import React from 'react';

const MessageParser = ({ children, actions }) => {
  const parse = (message) => {
    console.log("parse user message " + message);
    // remove points at the end
    let email;
    let hello;
    let name;
    let yes;
    let question;
    let no;
    let tv;
    let messageText = message;
    let wantCancelSubscr;
    let wantRefund;
    let hasProblem;
    let hasGoodEmotions;
    let hasBadEmotions;

    //actions.anyText(message);

    if ((message.toLowerCase()).includes('hello')
        || (message.toLowerCase()).includes('helli')
        || (message.toLowerCase()).includes('helo')
        || (message.toLowerCase()).includes('greeting')
        || (message.toLowerCase()).includes('hi')
        || (message.toLowerCase()).includes('hey') ) {
          hello = true;
        //  actions.handleHello();
    }

    if (message.toLowerCase().includes('i\'m')) {
               let splitArray = message.split("i\'m ");
              let nameValue = splitArray[1];
              if(nameValue.includes(" ")){
              let nameSplit = nameValue.split(" ");
              // get 1st part
              name = nameSplit[0];
              } else{
              name = nameValue;
              }
             // actions.handleName();
    }

     if (message.toLowerCase().includes('i am')) {
               let splitArray = message.split("i am ");
               let nameValue = splitArray[1];
               if(nameValue.includes(" ")){
               let nameSplit = nameValue.split(" ");
               name = nameSplit[0];
               // get 1st part
               } else{
               name = nameValue;
               }
     }

     if (message.toLowerCase().includes('my name is')) {
            let splitArray = message.toLowerCase().split("my name is ");
            console.log(splitArray);
            console.log(splitArray[0]);
            console.log(splitArray[1]);
            let nameValue = splitArray[1];
            if(nameValue.includes(" ")){
            let nameSplit = nameValue.split(" ");
            name = nameSplit[0];
            name = capitalizeFLetter(name);
             // get 1st part
            } else{
            name = capitalizeFLetter(nameValue);
            }
     }

    if ((message.toLowerCase()).includes('yes')
        || (message.toLowerCase()).includes('yeah')
        || (message.toLowerCase()).includes('sure')
        || (message.toLowerCase()).includes('agree')
        || (message.toLowerCase()).includes('ya')
        || (message.toLowerCase()).includes('ok!')
        || (message.toLowerCase()).includes('ok ')
        || (message.toLowerCase()).includes('ok,')
        || ((message.toLowerCase()).endsWith('ok') && (message.toLowerCase()).startsWith('ok'))
        || (message.toLowerCase()).endsWith(' ok')
        || (message.toLowerCase()).includes('yah')
        || (message.toLowerCase()).includes('yea')
        || (message.toLowerCase()).includes('yup')
        ) {
          yes = true;
         // actions.handleYes();
    }
    if (message.includes('@') && message.includes('.')) {

           if(message.includes(" ")){
           // get email from the message
           // split by " "

          //remove all points
          let cleaned = false;
          while (!cleaned){
          if (message[message.length-1] === "."
              || message[message.length-1] === " "
              || message[message.length-1] === ","
              || message[message.length-1] === "!"
              || message[message.length-1] === "?") {
             message = message.slice(0,-1);
          }  else{
            cleaned = true;
           }
          }

           let splitArray = message.split(" ");

             for(let i = 0; i < splitArray.length; i++){
               if(splitArray[i].includes('@') && splitArray[i].includes('.')){
             //   actions.handleEmail(splitArray[i]);

                email = splitArray[i];
               }
             }
           } else{
           email = message;
           //actions.handleEmail(message);
           }
    }

    if ((message.includes('refund') && (message.includes('cancel')
                                        || message.includes('subscr')))
        || message.includes('money back')) {

        wantRefund = true;
    }

    if (message.includes('subscr')
            || (message.includes('cancel')
                || message.includes('stop')
                || message.includes('unsubscr')
                || message.includes('end')
                || message.includes('stop')
                || message.includes('delete')
                )) {
     wantCancelSubscr = true;

   }

//    else{
//    if(email !== null && email !== undefined){
//    actions.handleEmail(email);
//    }
//    }

    if ((message.toLowerCase()).includes('tv') || (message.toLowerCase()).includes('telev')) {
         tv = true;

    }

      if ((message.toLowerCase()).includes('not')
            || (message.toLowerCase()).includes('n\'t')
            || (message.toLowerCase()).includes('no')
            || (message.toLowerCase()).includes('dis')) {
                // some negative answer
          no = true;
         // actions.handleNegation(message);
     }
     if ((message.toLowerCase()).includes('know')
          || (message.toLowerCase()).includes('remember')
          || (message.toLowerCase()).includes('understand')
          || (message.toLowerCase()).includes('figure')
          || (message.toLowerCase()).includes('aware')
          || (message.toLowerCase()).includes('believe')
          || (message.toLowerCase()).includes('feel')
          || (message.toLowerCase()).includes('sure')
          || (message.toLowerCase()).includes('think')
          || (message.toLowerCase()).includes('realize')
          || (message.toLowerCase()).includes('hope')
          || (message.toLowerCase()).includes('hoping')
          || (message.toLowerCase()).includes('clue')
          || (message.toLowerCase()).includes('have')) {
           // having knowledge or opinion about smth
          }
     if (((message.toLowerCase()).includes('app') || (message.toLowerCase()).includes('everdance')) &&
          ((message.toLowerCase()).includes('like')
          || (message.toLowerCase()).includes('love')
          || (message.toLowerCase()).includes('appreciate')
          || (message.toLowerCase()).includes('happy')
          || (message.toLowerCase()).includes('satisf')
          || (message.toLowerCase()).includes('pleased')
          || (message.toLowerCase()).includes('admire')
          || (message.toLowerCase()).includes('taste'))) {
          hasGoodEmotions = true;
     }
     if (((message.toLowerCase()).includes('app') || (message.toLowerCase()).includes('everdance')) &&
               ((message.toLowerCase()).includes('terrible')
               || (message.toLowerCase()).includes('awful')
               || (message.toLowerCase()).includes('bad')
               || (message.toLowerCase()).includes('upset')
               || (message.toLowerCase()).includes('disappoint')
               || (message.toLowerCase()).includes('disaster')
               || (message.toLowerCase()).includes('horrible'))) {
               hasBadEmotions = true;
     }

     if ((message.toLowerCase()).includes('afraid')
         || (message.toLowerCase()).includes('doubt')
         || (message.toLowerCase()).includes('fear')) {
         // some doubt
     }
     if ((message.toLowerCase()).includes('issue')
              || (message.toLowerCase()).includes('problem')
              || (message.toLowerCase()).includes('trouble')
              || (message.toLowerCase()).includes('error')
              || (message.toLowerCase()).includes('can’t take')
              || (message.toLowerCase()).includes('glitch')
              || (message.toLowerCase()).includes('can’t access')
              || (message.toLowerCase()).includes('don’t work')
              || (message.toLowerCase()).includes('won’t allow')
              || (message.toLowerCase()).includes('won’t allow')
              || (message.toLowerCase()).includes('freez')
              || (message.toLowerCase()).includes('blank')) {
      hasProblem = true;

     }
      if ((message.toLowerCase()).includes('help')
              || (message.toLowerCase()).includes('how to')
              || (message.toLowerCase()).includes('assist')
              || (message.toLowerCase()).includes('please')
              || (message.toLowerCase()).includes('?')
              || (message.toLowerCase()).includes('why')
              || (message.toLowerCase()).includes('how')) {
              // request for action of answer
              question = true;
              //actions.handleAsk(message);
       }

    if(wantCancelSubscr){
      actions.handleSelectSubscriptionQuestion(1, email, name, hello, yes, no, message);
    } else if (wantRefund){
      actions.handleSelectSubscriptionQuestion(4, email, name, hello, yes, no, message);
   } else if(tv){
      actions.handleSelectAppQuestion(1, email, name, hello, yes, no);
   } else if(email !== null && email !== undefined){
      actions.handleEmail(email, null);
   } else if(yes) {
      actions.handleYes();
   } else if(no) {
      actions.handleNegation();
   } else if(hello) {
     actions.handleHello(name);
   } else if(hasProblem) {
      actions.handleError(message, hello, name, email);
   } else if(hasGoodEmotions) {
      actions.handleGoodEmotions(message, hello, name, email);
   } else if(hasBadEmotions) {
     actions.handleBadEmotions(message, hello, name, email);
   } else {
      //answers to bot questions or just want to chat
      actions.anyText(message, hello, name, yes, no);
   }

  };

  function capitalizeFLetter(word) {

      return (word[0].toUpperCase() + word.slice(1));
  }


  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions: actions,
        });
      })}
    </div>
  );
};

export default MessageParser;