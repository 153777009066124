import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

import styles from "./CheckboxCard.module.scss";
import { useState } from 'react';

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    position: 'relative',
    width: 20,
    height: 20,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#FFFFFF',
    '.Mui-focusVisible &': {
      outline: '1px solid #BBBBBB',
    },
}));
  
const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: 'linear-gradient(334.65deg, #DD3787 5.23%, #FD6C59 83.78%)',
    backgroundImage: 'linear-gradient(334.65deg, #DD3787 5.23%, #FD6C59 83.78%)',
    boxShadow: '0px 0px 2px rgba(225, 87, 104, 0.7)',
    '&:before': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 10,
        height: 8,
        transform: 'translate(-50%, -50%)',
        backgroundImage:
        "url('/images/icons/check-icon.svg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '10px 8px',
        content: '""',
    },
});



const CheckboxCard = props => {
    const { data, selectCard, smallCard } = props;
    const [card, setCard] = useState(data);

    const clickCard = () => {
        setCard({...card, checked: !card.checked})
        selectCard({...card, checked: !card.checked});
    }

    return (
        <div className={`${styles.cardContainer} ${card.checked ? styles.activeCard : ''} ${smallCard ? styles.smallCardContainer : ''} flex-between`}
             onClick={clickCard}
        >
            <div className='flex-row'>
                {!card.withoutImage &&
                        (<img className={styles.cardIcon} src={card.icon} alt="" />)
                }
                <p className={`${card.withoutImage ? '' : styles.cardTitle} cardTitle`}>{card.title}</p>
            </div>
            <Checkbox
                sx={{
                    '&:hover': { bgcolor: 'transparent' },
                }}
                disableRipple
                checked={card.checked}
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                {...props}
            />
        </div>
    )
}   

export default CheckboxCard;