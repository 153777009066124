import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as ReactDOM from 'react-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {useEffect, useState, useRef} from 'react';

// set our material theme to dark mode
const theme = createTheme({
	palette: {
		type: "dark"
	}
});

// add an interface that defines the new props
export interface IAlertProps {
	message: string;
	title: string;
}

const AlertRoot: React.FC<IAlertProps> = (props) => {

    const { message, title, alertRef, setSaveSessionValue } = props;

    let root = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let div = document.getElementById(rootID);
        console.log(alertRef.current);
        console.log(div);
        root = alertRef;
        root.current = div;
    }, []);

    function Close() {
        console.log(alertRef.current);
        root.current?.remove();
    }

	return (
        // pass in our dark theme
		<ThemeProvider theme={theme}>
            {/* Sets an absolute root component as a clickaway handler and
            	a container for content, which is appended to our root element */}
			<Dialog
				onClose={() => Close()}
				open={true}
				disablePortal={true}
			>
                 {/* content components */}
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{message}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {
					Close()
					setSaveSessionValue(false)
					}}>
						{"Delete all session history"}
					</Button>
					<Button onClick={() => {
					Close()
					setSaveSessionValue(true)
					}}>
                        {"Nevermind, don't delete."}
                    </Button>
				</DialogActions>
			</Dialog>
		</ThemeProvider>
	);
}

// an ID to give our root element, can be what ever you like
const rootID = "alert-dialog";


// a base function that appends our component to the document body
function Create(message: string, title: string, alertRef, setSaveSessionValue) {
    console.log(alertRef)
    // see if we have an element already
	let div = document.getElementById(rootID);
	if (!div) {
		div = document.createElement("div");
		div.id = rootID;
		//div.setAttribute("ref", alertRef);
		document.body.appendChild(div);
	}
    // render with react
	ReactDOM.render(<AlertRoot ref={alertRef}
	                 message={message}
                     title={title}
                     alertRef={alertRef}
                     setSaveSessionValue={setSaveSessionValue}/>, div);
}

// expose a function that we can reuse in our apps to call the alert
export default function Alert(message: string, title: string = "Alert", alertRef, setSaveSessionValue) {
    console.log(alertRef)
	Create(message, title, alertRef, setSaveSessionValue);
}

