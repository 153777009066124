
async function userSession(chatId, userId, userName, userNickname, userEmail, question, message) {

    // send info to server about this user
    let chatID = null;
    return await fetch('https://t2lowguqdl.execute-api.us-east-1.amazonaws.com/dev/user_session', {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            chat_id: localStorage.getItem('chat_bot_id'),
            user_id: userId,
            user_name: userName,
            user_nickname: userNickname,
            email: userEmail,
            question: question,
            message: message
        })
    }).then(async (response) => {
        console.log("user session result");
        let resultBody = response.json();
        await resultBody.then(async (json) => {
            console.log(json);
            console.log(json.result.chat_id);
            chatID = json.result.chat_id
            //save to localstorage
            localStorage.setItem('chat_bot_id', chatID);
        });
    });
     return chatID;
}

export default userSession

