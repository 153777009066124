
import React, { useEffect, useState } from 'react';
import data from "../../core/static/chatbot/plan_change.json";
import useJsonParse from "../../hooks/useJsonParse";

import CheckboxCard from "./checkboxCard/CheckboxCard";
import styles from "./Subscription.module.scss";

const PlanChangeOptions = props => {
  const { setState, actionProvider } = props;
  const [imageUrl, setImageUrl] = useState('');
  const [cards, setCards] = useState();
  const parsedData = useJsonParse(data);
  const [activeCards, setActiveCards] = useState([]);

  useEffect(() => {
          setCards(parsedData);
      }, [parsedData]);

   const selectCard = card => {
          if(card.checked) {
              setActiveCards((prev => [...prev, card]))
          } else {
              const removeCardIndex = activeCards.findIndex(el => el.id === card.id);
              setActiveCards([...activeCards.slice(0, removeCardIndex), ...activeCards.slice(removeCardIndex + 1)])
          }
          
           actionProvider.handleChangePlanOption(card.id);
      }
//   return (
//     <div>
//       <img src="/images/no_refund.png" alt='we do not make refund' />
//     </div>
//   );
 return (
        <>
            <div className={`${styles.mainContainer} flex-column`}>
{/*                 <h1 className={styles.cardTitle}>Select your question?</h1> */}
{/*                 <span className={`${styles.marginTitle} componentSubtitle`}>We will try to help you.</span> */}
                <div className={`flex-column cardsContainer`}>
                    <div className={`flex-column cardsWrapper`}>
                        { cards?.map(card => ( <CheckboxCard key={card.id} data={card} selectCard={selectCard}/> )) }
                    </div>
                </div>
            </div>
        </>
    )
};

export default PlanChangeOptions;