import config from './config.js';
import MessageParser from './MessageParser.jsx';
import ActionProvider from './ActionProvider.jsx';
import Chatbot from 'react-chatbot-kit'
import Alert from './service/alert.jsx';
import 'react-chatbot-kit/build/main.css'
import './chat_bot.css'
import {useEffect, useState, useRef} from 'react';

const ChatBot = () => {

 const [showBot, toggleBot] = useState(true);
 const [saveSession, setSaveSession] = useState(false);
 const [showDialog, setShowDialog] = useState(false);
 const cancelRef = useRef();

 const alertRef = useRef();

 const open = () => {
 Alert("ChatBot session settings", "Please select weather you want to save or delete your chat session history after closing this tab.", alertRef, setSaveSessionValue);
 }

 const setSaveSessionValue = (value) => {
 console.log("setSaveSessionValue " + value);
 setSaveSession(value);
 localStorage.setItem('setSaveSessionValue', value);
 }

 let pending = true;

 useEffect(() => {
   function beforeUnload(e: BeforeUnloadEvent) {
     if (!pending) return;
     toggleBot(false);

     e.preventDefault();

   }

   window.addEventListener('beforeunload', beforeUnload);

   return () => {
     window.removeEventListener('beforeunload', beforeUnload);
   };
 }, [pending]);

  const saveMessages = (messages, HTMLString) => {
   if(saveSession){
       console.log('save messages');
       localStorage.setItem('chat_messages', JSON.stringify(messages));
   }
  // ask to save session

  };

  const loadMessages = () => {
   console.log('loadMessages');
   console.log("setSaveSessionValue " + localStorage.getItem('setSaveSessionValue'));
   if(localStorage.getItem('setSaveSessionValue') !== null
      && localStorage.getItem('setSaveSessionValue') !== undefined
      && localStorage.getItem('setSaveSessionValue') === true){
   const messages = JSON.parse(localStorage.getItem('chat_messages'));
       console.log(messages);
       return messages;
   } else{
   return null;
   }

  };

  return (
    <div>
      {showBot && (<Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
        headerText='EverBot'
        placeholderText='Enter your message'
        messageHistory={loadMessages()}
        saveMessages={saveMessages}
        runInitialMessagesWithHistory
        disableScrollToBottom
      />)}

      <button onClick={open}>Chat settings</button>
    </div>
  );
};

export default ChatBot;